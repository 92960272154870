import { isEmpty } from "lodash";
import Switch from "../assets/Switch.png";
import Router from "../assets/Router.png";
import Wireless from "../assets/Wireless.png";
import { createUnit, evaluate } from "mathjs";
import jwt_decode from "jwt-decode";
import { isAdmin } from "../services/api.service";

// routes

const productListRoute = "/products";
const productDetailRoute = "product-details";
const uploadTemplateRoute = "/upload-template";
const editTemplateRoute = "/edit-template";
const addProductsRoute = "/add-products";

const SubCategoriesRouteMap = {
  "/routing-scale": "routing",
  "/routing-throughput": "routing_throughput",
  "/switching-scale": "switching",
  "/wireless-wlc-scale": "wireless_wlc",
  "/wireless-ap-scale": "wireless_ap"
};

const pathMapping = {
  "/routing-scale": 0,
  "/routing-throughput": 0,
  "/switching-scale": 1,
  "/wireless-wlc-scale": 2,
  "/wireless-ap-scale": 2,
  "/upload-template": 3,
  "/audit": 3,
  "/manage-admins": 3,
  "/user-feedback": 3
};

//RegEx

const ESCAPE_REGEX = `([.?*+^$[\\]\\\\(){}|-])`;
const PRODUCT_CARD_ESCAPE_REGEX = `([.?@!#%&_*+^$[\\]\\\\()<>{}|-])`;
const PRODUCT_FEATURES_REGEX = `"\\s+|\\s+"`;
const EMAIL_REGEX = `^(\\w+)([\\w.]+)@([\\w]+)\\.(\\w{2,8})(\\.\\w{2,8})?$`;
const TREE_REGEX = `([.?*+^$[\\]\\\\(){}|-])`;
const KBPS_REGEX_S = `(k|K)b(p|P)(s|S)`;
const KBPS_REGEX = `(k|K)B(p|P)(s|S)`;
const MBPS_REGEX_S = `(m|M)b(p|P)(s|S)`;
const MBPS_REGEX = `(m|M)B(p|P)(s|S)`;
const GBPS_REGEX_S = `(g|G)b(p|P)(s|S)`;
const GBPS_REGEX = `(g|G)B(p|P)(s|S)`;

// message

const SOMETHING_WENT_WRONG = "Something went wrong";
const TEMPLATE_ERROR = "please re-upload template";
const PRODUCT_ERROR = "please re-upload file";
const SDM_MESSAGE =
  "Some of the features share the same hardware resources, therefore a change in the scale of one feature may impact the max scale of other features.";

const SDA_KEYWORDS = [
  "min.",
  "min",
  "minimum",
  "max.",
  "max",
  "maximum",
  "default"
];

// category

const CATEGORY_MAPPING = {
  routing: "Routing Scale",
  routing_throughput: "Routing Throughput",
  switching: "Switching Scale",
  wireless_wlc: "WLC Scale",
  wireless_ap: "AP Scale"
};

export const SWITCHING = "switching";
export const ROUTING_THROUGHPUT = "routing_throughput";
export const ABSOLUTE = "absolute";
export const RELATIVE = "relative";
export const TABULAR = "tabular";
export const GRAPH = "graph";

export const COLUMN_INDEXES = [0, 1, 2, 3];

const getPath = category => {
  const idx = Object.values(SubCategoriesRouteMap).findIndex(
    subCategory => subCategory === category
  );
  return Object.keys(SubCategoriesRouteMap)[idx];
};

const DEFAULT_CATEGORY = "routing";

// functions

const chooseDefaultImage = category => {
  let image = "";
  switch (category) {
    case "switching":
      image = Switch;
      break;
    case "routing":
      image = Router;
      break;
    case "routing_throughput":
      image = Router;
      break;
    case "wireless_wlc":
      image = Wireless;
      break;
    case "wireless_ap":
      image = Wireless;
      break;
    default:
      image = Switch;
  }
  return image;
};

const productPrefix = name => {
  if (new RegExp(KBPS_REGEX_S).test(name)) return "Kbps";
  else if (new RegExp(KBPS_REGEX).test(name)) return "KBps";
  else if (new RegExp(MBPS_REGEX_S).test(name)) return "Mbps";
  else if (new RegExp(MBPS_REGEX).test(name)) return "MBps";
  else if (new RegExp(GBPS_REGEX_S).test(name)) return "Gbps";
  else if (new RegExp(GBPS_REGEX).test(name)) return "GBps";
  return "";
};

const COLORS_FOR_COMPARE = ["#01C5C4", "#B8DE6F", "#ffe066", "#FFA856"];
const ALPHA = "0f";

const parseFeatureValue = data => {
  createUnit(
    {
      bps: {
        definition: "1",
        prefixes: "binary_short"
      },
      Bps: {
        definition: "8 bps",
        prefixes: "binary_short"
      },
      k: {
        definition: "1000"
      },
      K: {
        definition: "1000"
      },
      m: {
        definition: "1000 k"
      },
      M: {
        definition: "1000 K"
      },
      KB: {
        definition: "1 kB"
      },
      Kb: {
        definition: "1 kb"
      },
      mB: {
        definition: "1000 KB"
      },
      mb: {
        definition: "1000 Kb"
      },
      MB: {
        definition: "1000 KB"
      },
      Mb: {
        definition: "1000 Kb"
      },
      Kbps: {
        definition: "1000 bps"
      },
      KBps: {
        definition: "1000 Bps"
      },
      mbps: {
        definition: "1000 Kbps"
      },
      mBps: {
        definition: "1000 KBps"
      },
      Mbps: {
        definition: "1000 Kbps"
      },
      MBps: {
        definition: "1000 KBps"
      },
      dBm: {
        definition: "1"
      },
      dBi: {
        definition: "1"
      }
    },
    { override: true }
  );

  if (typeof data === "number") {
    if (data >= 0) {
      return {
        value: data,
        intValue: data,
        random: false,
        unit: "number"
      };
    } else {
      return {
        value: data,
        intValue: 0,
        random: true,
        unit: "number"
      };
    }
  } else if (typeof data === "string") {
    if (["NA", "??"].includes(data)) {
      return {
        value: data,
        intValue: 0,
        random: false,
        unit: "any"
      };
    } else {
      let intValue;
      let unit;
      let random = false;
      try {
        const unitObj = evaluate(data);
        intValue = unitObj.value;
        unit = unitObj.units[0].unit.name;
      } catch (error) {
        intValue = 0;
        random = true;
        unit = "any";
      }
      return {
        value: data,
        intValue,
        random,
        unit
      };
    }
  } else {
    return {
      value: "-",
      intValue: 0,
      random: false
    };
  }
};

const checkAllUnitsAreSame = unitList => {
  const numberUnits = ["number", "k", "K", "m", "M", "any"];
  const byteUnits = ["b", "B", "Kb", "KB", "Mb", "MB", "mb", "mB", "any"];
  const bpsUnits = [
    "bps",
    "Bps",
    "KBps",
    "Kbps",
    "MBps",
    "Mbps",
    "mBps",
    "mbps",
    "any"
  ];

  const allEqual = arr => arr.every(v => v === arr[0]);

  return (
    allEqual(unitList.filter(item => item !== "any")) ||
    unitList.every(unit => numberUnits.includes(unit)) ||
    unitList.every(unit => byteUnits.includes(unit)) ||
    unitList.every(unit => bpsUnits.includes(unit))
  );
};

const checkIfUserIsAdmin = token => {
  const decodedToken = jwt_decode(token);
  return isAdmin(decodedToken["email"]).then(res => {
    return !isEmpty(res?.data?.admins);
  });
};

export const checkData = value => {
  return value === "??" ? "-" : value;
};

function getVersionValue(cellData, idx) {
  if (!isEmpty(cellData) && typeof cellData === "object") {
    let cellVersionValue = Object.values(cellData)[idx];
    if (!["string", "number"].includes(typeof cellVersionValue)) return "-";
    return cellVersionValue === null || cellVersionValue === ""
      ? "-"
      : checkData(cellVersionValue);
  }
  return "-";
}

function getDelta(cellData) {
  if (typeof cellData === "object" && !isEmpty(cellData)) {
    const deltaValue = Object.values(cellData)[2];
    return deltaValue === null ? "-" : deltaValue;
  }
  return "-";
}

export const feature_actions = {
  ADD_SDM_CATEGORY: "add_sdm_category",
  DELETE_SDM_CATEGORY: "delete_sdm_category",
  ADD_FEATURE_ABOVE: "add_feature_above",
  ADD_FEATURE_BELOW: "add_feature_below",
  ADD_FEATURE_CHILD: "add_feature_child",
  DELETE_FEATURE: "delete_feature",
  EDIT_FEATURE: "edit_feature",
  REVERT_DELETE_FEATURE: "revert_delete_feature",
  REMOVE_EDITABLE_FEATURE: "remove_editable_feature",
  EDIT_FEATURE_DESCRIPTION: "edit_feature_description"
};

export const DELETED_FEATURES_SPLITTER = "!!@@!!";
export const SPLITTER_FOR_FEATURES = "!!@@!!";
export const FEATURE_DESC_SPLITTER = "!!@@!!";
export const BLANK_FEATURE_MESSAGE = "Feature name cannot be blank.";
export const DUPLICATE_FEATURE_MESSAGE =
  "Feature name already exists under same category.";

const getParentsPathJoined = (parents, key) => {
  if (parents.length > 0)
    return parents.join(FEATURE_DESC_SPLITTER) + FEATURE_DESC_SPLITTER + key;
  return key;
};

export {
  productListRoute,
  productDetailRoute,
  uploadTemplateRoute,
  editTemplateRoute,
  addProductsRoute,
  ESCAPE_REGEX,
  PRODUCT_FEATURES_REGEX,
  PRODUCT_CARD_ESCAPE_REGEX,
  EMAIL_REGEX,
  TREE_REGEX,
  SOMETHING_WENT_WRONG,
  SDA_KEYWORDS,
  TEMPLATE_ERROR,
  PRODUCT_ERROR,
  productPrefix,
  chooseDefaultImage,
  COLORS_FOR_COMPARE,
  CATEGORY_MAPPING,
  DEFAULT_CATEGORY,
  parseFeatureValue,
  checkAllUnitsAreSame,
  checkIfUserIsAdmin,
  ALPHA,
  SubCategoriesRouteMap,
  getPath,
  pathMapping,
  getVersionValue,
  getDelta,
  SDM_MESSAGE,
  getParentsPathJoined
};
