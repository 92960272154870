import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useErrorHandling } from "../../components/CustomHooks/CustomHooks";
import history from "../../router/history";
import { analyticsData, ssologin } from "../../services/api.service";
import "./LoginScreen.scss";

export default function LoginScreen(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [displayError] = useErrorHandling();

  useEffect(() => {
    if (props.message) {
      enqueueSnackbar(props.message, {
        variant: "error"
      });
    }
    if (history?.location?.hash) {
      const queryData = queryString.parse(history.location.hash);
      const { access_token, id_token } = queryData;
      localStorage.setItem("ccat_id_token", id_token);
      localStorage.setItem("ccat_access_token", access_token);
      analyticsData({ is_user_signin: true }).then(() => {});
      history.push("/products");
    } else if (localStorage.getItem("ccat_id_token")) {
      history.push("/products");
    } else {
      logingUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logingUser = () => {
    ssologin()
      .then(res => {
        const loginUrl = res.data.login_url;
        window.location.assign(loginUrl);
      })
      .catch(displayError);
  };

  return <div></div>;
}

LoginScreen.propTypes = {
  message: PropTypes.string
};

LoginScreen.defaultProps = {
  message: ""
};
