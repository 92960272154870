import { useSnackbar } from "notistack";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { SOMETHING_WENT_WRONG } from "../../constants/constant";

export const useUnsavedChangesWarning = isFormDirty => {
  useEffect(() => {
    const handleBeforeUnload = event => {
      (event || window.event).preventDefault();
      if (isFormDirty) {
        (event || window.event).returnValue = "changes must be saved";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);
};

export function useOnScreen(threshold = 1.0) {
  const ref = useRef();
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        threshold
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [ref, isIntersecting];
}

export const useErrorHandling = () => {
  const { enqueueSnackbar } = useSnackbar();
  const displayError = (error, defaultMsg = "") => {
    if (axios.isCancel(error)) {
      return;
    } else if (error?.response?.status === 409) {
      enqueueSnackbar(
        "Administrative action is in progress. Please try after some time.",
        {
          variant: "warning",
          autoHideDuration: 3000
        }
      );
    } else if (error?.response?.data?.detail) {
      enqueueSnackbar(error?.response?.data?.detail, {
        variant: "error",
        autoHideDuration: 3000
      });
    } else if (defaultMsg !== "") {
      enqueueSnackbar(defaultMsg, {
        variant: "error",
        autoHideDuration: 3000
      });
    } else if (error) {
      enqueueSnackbar(SOMETHING_WENT_WRONG, {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };
  return [displayError];
};
