import { axiosInstance } from "./axios-instance";
import axios from "axios";
import qs from "query-string";

let cancelToken;

function verifyImportFile(category, file) {
  return axiosInstance.post("/import/verify/features/" + category, file, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

function importFile(category, payload) {
  return axiosInstance.post("/products/import/" + category, null, {
    params: {
      ...payload
    }
  });
}

function getSeries(category) {
  return axiosInstance.get(`/products/${category}/series`);
}

function getProducts(type, params) {
  if (cancelToken) {
    cancelToken.cancel("Only one request allowed at a time.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(`/products/${type}/list`, {
    params: {
      ...params
    },
    cancelToken: cancelToken.token
  });
}

function verifyTemplateFile(category, file) {
  return axiosInstance.post("template/verify/features/" + category, file, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

function uploadStaticTemplate(category, file) {
  return axiosInstance.post("/template/upload/" + category, file, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

function syncTemplateFeatures(category) {
  return axiosInstance.post("template/sync/features/" + category);
}

function getProductDetail(id) {
  return axiosInstance.get(`/products/${id}`);
}

function updateProductDetail(id, payload) {
  return axiosInstance.put(`/products/${id}`, {
    ...payload
  });
}

function deleteProduct(id, payload) {
  return axiosInstance.delete(`/products/${id}`, {
    ...payload
  });
}
function listTemplate() {
  return axiosInstance.get("/template/list");
}

function downloadTemplate(category) {
  return axiosInstance.get("/template/download/" + category, {
    responseType: "blob"
  });
}

function downloadTemplateWithVersion(category, version) {
  return axiosInstance.get("/template/download/" + category, {
    params: { template_version: version },
    responseType: "blob"
  });
}

let reorderCancelToken;
function reorderProducts(category, payload) {
  if (reorderCancelToken) {
    reorderCancelToken.cancel("");
  }
  reorderCancelToken = axios.CancelToken.source();
  return axiosInstance.post(`/products/${category}/reorder`, payload, {
    cancelToken: reorderCancelToken.token
  });
}

function getProductFeatures(category) {
  return axiosInstance.get("/template/features/" + category);
}

function compareProducts(category, payload, compareCancelToken) {
  return axiosInstance.post(`/products/${category}/compare`, payload, {
    cancelToken: compareCancelToken
  });
}

function relativeCompareProducts(category, payload, relativeCancelToken) {
  return axiosInstance.post(`/products/${category}/relative-compare`, payload, {
    cancelToken: relativeCancelToken
  });
}

function addFeatureTemplate(category, payload) {
  return axiosInstance.post(`/template/${category}`, payload);
}

function downloadProductCompare(payload, category, type) {
  return axiosInstance.post(
    `/products/${type}/download/${category}`,
    {
      ...payload
    },
    { responseType: "blob" }
  );
}

function ssologin() {
  return axiosInstance.post(`/ssologin`);
}

function slologout() {
  return axiosInstance.post(`/slologout`);
}

function getAuditLogs(payload) {
  return axiosInstance.get("audit-logs", {
    params: { ...payload },
    paramsSerializer: params => {
      return qs.stringify(params);
    }
  });
}

function getAdmins() {
  return axiosInstance.get("audit-logs/admins");
}

function getAdminsDetails() {
  return axiosInstance.get("admins");
}

function addAdmin(payload) {
  return axiosInstance.post("admins", payload);
}

function removeAdmin(id) {
  return axiosInstance.delete(`/admins/${id}`);
}

function isAdmin(email) {
  return axiosInstance.get("admins", {
    params: { email },
    paramsSerializer: params => {
      return qs.stringify(params);
    }
  });
}

function getFeedbackQuestions(active = true) {
  return axiosInstance.get("feedback/questions", {
    params: { active }
  });
}

function addFeedback(payload) {
  return axiosInstance.post("feedback/submit", payload);
}

function getFeedbackAnswers(params) {
  return axiosInstance.get("feedback", { params });
}

function deleteAnswer(params) {
  return axiosInstance.delete(`/feedback?${params}`);
}

function saveProducts(payload, category) {
  return axiosInstance.post(`/products/${category}`, payload);
}

function uploadProductImage(payload) {
  return axiosInstance.post("upload-product-image", payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

function createBackup(category, payload) {
  return axiosInstance.post(`/products/backups/${category}`, payload);
}

function listBackups() {
  return axiosInstance.get("/products/backups/list");
}

function downloadBackup(payload) {
  return axiosInstance.post("/products/download", payload, {
    responseType: "blob"
  });
}
function restoreBackup(backup_id, overwrite) {
  return axiosInstance.post(
    `/products/restore/${backup_id}${overwrite ? "?overwrite=true" : ""}`
  );
}

function analyticsData(payload) {
  return axiosInstance.post("/analytics/data", payload);
}

function productStatistics(payload) {
  return axiosInstance.get("/analytics/products", {
    params: { ...payload },
    paramsSerializer: params => {
      return qs.stringify(params);
    }
  });
}

function userStatistics(payload) {
  return axiosInstance.get("/analytics/users", {
    params: { ...payload },
    paramsSerializer: params => {
      return qs.stringify(params);
    }
  });
}

function categoryDistribution(payload) {
  return axiosInstance.get("/analytics/category-distribution", {
    params: { ...payload },
    paramsSerializer: params => {
      return qs.stringify(params);
    }
  });
}

export {
  verifyImportFile,
  importFile,
  getSeries,
  getProducts,
  getProductDetail,
  updateProductDetail,
  deleteProduct,
  verifyTemplateFile,
  uploadStaticTemplate,
  syncTemplateFeatures,
  listTemplate,
  downloadTemplate,
  downloadTemplateWithVersion,
  reorderProducts,
  getProductFeatures,
  compareProducts,
  downloadProductCompare,
  slologout,
  ssologin,
  getAuditLogs,
  getAdmins,
  getAdminsDetails,
  addAdmin,
  removeAdmin,
  isAdmin,
  relativeCompareProducts,
  addFeatureTemplate,
  getFeedbackQuestions,
  addFeedback,
  getFeedbackAnswers,
  deleteAnswer,
  saveProducts,
  uploadProductImage,
  analyticsData,
  listBackups,
  createBackup,
  downloadBackup,
  restoreBackup,
  productStatistics,
  userStatistics,
  categoryDistribution
};
