import axios from "axios";
import { slologout } from "./api.service";
// define api endpoints here

const API_BASE_URL = "/api";

const axiosConfig = {
  baseURL: API_BASE_URL,
  timeout: 120000
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(
  // interceptors for request
  config => {
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("ccat_id_token");
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  // interceptor for response
  response => response,
  error => {
    if ([401, 403].includes(error?.response?.status)) {
      localStorage.removeItem("ccat_id_token");
      localStorage.removeItem("ccat_access_token");
      slologout().then(res => {
        const logoutUrl = res.data.logout_url;
        window.location.assign(logoutUrl);
      });
    } else if (error.response && error.response.status === 422) {
      let details = error.response.data.message[0];
      let message = `Error: ${details.msg}`;
      error.response.data = { detail: message };
    } else if (error.response && error.response.data.detail) {
      // pass
    } else if (error.response && error.response.statusText) {
      error.response.data = { detail: error.response.statusText };
    } else {
      if (error.response) {
        error.response.data.detail =
          error.message || "Error while connecting to server";
      } else {
        error.response = {
          data: {
            detail: error.message || "Error while connecting to server"
          }
        };
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
