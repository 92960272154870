import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import history from "./history";
import LoginScreen from "../pages/LoginScreen/LoginScreen";

const MainPage = lazy(() => import("../pages/MainPage/MainPage"));

export default function RouterSwitch(props) {
  return (
    <Router history={history}>
      <Suspense
        fallback={
          <CircularProgress
            style={{
              margin: "20vh 46vw"
            }}
          />
        }
      >
        <Switch>
          {/* we can define other routes here */}
          <Route key="default" path="/" exact>
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login" component={() => <LoginScreen />} />
          <Route path="*" component={() => <MainPage />} />
        </Switch>
      </Suspense>
    </Router>
  );
}
