import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import RouterSwitch from "./router/RouterSwitch";
import "./App.scss";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0d274d",
      light: "#12376d",
      dark: "#0b2141"
    }
  },
  typography: {
    fontFamily: ["CiscoSans", "sans-serif"]
  }
});

const menuTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0d274d",
      light: "#12376d",
      dark: "#0b2141"
    }
  },
  typography: {
    fontFamily: ["CiscoSans", "sans-serif"]
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});

function App() {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          preventDuplicate={true}
        >
          <RouterSwitch />
        </SnackbarProvider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
export { menuTheme };
